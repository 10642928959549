<script lang="ts" setup>
import { useHeaderMainStore } from '~/store'
// import HeaderSubMenuNewsList from '~/components/Header/HeaderSubMenuNewsList.vue'

const store = useHeaderMainStore()
const route = useRoute()

interface Props {
    isOpened: boolean
}

defineProps<Props>()
</script>

<template>
    <div ref="popupElRef" class="popup" v-bind="$attrs">
        <div class="popup__container">
            <!-- Popup news -->
            <!-- <HeaderSubMenuNewsList /> -->

            <!-- Popup menu -->
            <div class="popup__menu popup-menu">
                <div class="popup-menu__items">
                    <AppNuxtLinkLocale
                        tag="h5"
                        :to="{ path: '/industries' }"
                        class="popup-menu__title"
                    >
                        {{ $t('industrySectors') }}
                    </AppNuxtLinkLocale>
                    <div
                        v-if="store.industries"
                        class="popup-menu__links"
                    >
                        <AppNuxtLinkLocale
                            v-for="item in store.industries"
                            :key="item.id + item.name"
                            :to="{
                                path: `/industries/${item.slug}`
                            }"
                            :class="{
                                'popup-menu__link-active':
                                    route.params?.slug === item.slug
                            }"
                            class="popup-menu__link"
                        >
                            {{
                                item.name.replace(/\n/g, '\r\n') ||
                                'no name in translations'
                            }}
                        </AppNuxtLinkLocale>
                    </div>
                </div>
                <div class="popup-menu__items">
                    <AppNuxtLinkLocale
                        tag="h5"
                        :to="{ path: '/specializations' }"
                        class="popup-menu__title"
                    >
                        {{ $t('practiceAreas') }}
                    </AppNuxtLinkLocale>
                    <div
                        v-if="store.specializations"
                        class="popup-menu__links"
                    >
                        <AppNuxtLinkLocale
                            v-for="item in store.specializations"
                            :key="item.id + item.name"
                            :class="{
                                'popup-menu__link-active':
                                    route.params?.slug === item.slug
                            }"
                            :to="{
                                path: `/specializations/${
                                    item.slug || 'no-dep-slug'
                                }`
                            }"
                            class="popup-menu__link"
                        >
                            {{
                                item.name.replace(/\n/g, '') ||
                                'no name in translations'
                            }}
                        </AppNuxtLinkLocale>
                    </div>
                </div>
            </div>

            <!-- Popup links -->
            <div class="popup__links">
                <AppNuxtLinkLocale
                    :to="{ path: '/insights' }"
                    href="#"
                    class="popup-menu__title"
                    >{{ $t('insights') }}
                </AppNuxtLinkLocale>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
