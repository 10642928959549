<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.625 3.71875H4.375C3.9696 3.72105 3.58145 3.88311 3.29478 4.16978C3.00811 4.45645 2.84605 4.8446 2.84375 5.25V15.75C2.84605 16.1554 3.00811 16.5436 3.29478 16.8302C3.58145 17.1169 3.9696 17.279 4.375 17.2812H16.625C17.0304 17.279 17.4186 17.1169 17.7052 16.8302C17.9919 16.5436 18.154 16.1554 18.1562 15.75V5.25C18.154 4.8446 17.9919 4.45645 17.7052 4.16978C17.4186 3.88311 17.0304 3.72105 16.625 3.71875ZM4.375 5.03125H16.625C16.683 5.03125 16.7387 5.0543 16.7797 5.09532C16.8207 5.13634 16.8438 5.19198 16.8438 5.25V6.5975L10.5 9.765L4.15625 6.5975V5.25C4.15625 5.19198 4.1793 5.13634 4.22032 5.09532C4.26134 5.0543 4.31698 5.03125 4.375 5.03125ZM16.625 15.9688H4.375C4.31698 15.9688 4.26134 15.9457 4.22032 15.9047C4.1793 15.8637 4.15625 15.808 4.15625 15.75V8.05875L10.2025 11.0863C10.2949 11.1325 10.3967 11.1565 10.5 11.1565C10.6033 11.1565 10.7051 11.1325 10.7975 11.0863L16.8438 8.05875V15.75C16.8438 15.808 16.8207 15.8637 16.7797 15.9047C16.7387 15.9457 16.683 15.9688 16.625 15.9688Z"
            fill="#212121"
        />
    </svg>
</template>
