<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M26.348 11.2504L23.3656 24.6982C23.2253 25.3315 22.5762 25.6579 22.044 25.3571L18.2719 23.2268L16.4883 26.4191C16.003 27.2893 14.775 26.9118 14.775 25.8945V22.3373C14.775 22.0623 14.8802 21.8 15.0615 21.608L22.4124 13.9309C22.4065 13.8349 22.313 13.7518 22.2195 13.822L13.4477 20.5012L10.5004 18.8378C9.81041 18.4475 9.83965 17.3535 10.553 17.0145L25.0851 10.086C25.781 9.75326 26.5296 10.4379 26.3483 11.2503L26.348 11.2504Z"
            fill="#050F38"
        />
    </svg>

    <!-- <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5168 0.928777L11.1703 10.4558C11.0599 10.9045 10.5492 11.1357 10.1304 10.9226L7.1626 9.41339L5.75927 11.675C5.37744 12.2915 4.41121 12.0241 4.41121 11.3033V8.78329C4.41121 8.58844 4.49399 8.40259 4.63664 8.26662L10.4203 2.82777C10.4157 2.75978 10.3421 2.7009 10.2685 2.75067L3.36692 7.48248L1.048 6.30406C0.50513 6.02756 0.528135 5.2525 1.08939 5.01233L12.5232 0.103894C13.0707 -0.131831 13.6597 0.353175 13.5171 0.928736L13.5168 0.928777Z"
      fill="#2A2A2A" />
  </svg> -->
</template>
