import { ref, onMounted, onUnmounted } from 'vue'

export default function useElementScrollVisibility(
    headerHeight: number,
    elementId: string
) {
    const isElementVisible = ref(false)

    function scrollHandler() {
        const element = document.getElementById(elementId)
        if (!element) return

        const rect = element.getBoundingClientRect()
        const viewportHeight =
            window.innerHeight ||
            document.documentElement.clientHeight

        const topVisible =
            rect.top > headerHeight && rect.top < viewportHeight
        const bottomVisible =
            rect.bottom > headerHeight && rect.bottom < viewportHeight

        isElementVisible.value = !(topVisible || bottomVisible)
    }

    const route = useRoute()
    watch(
        () => route.fullPath,
        () => {
            isElementVisible.value = false
        },
        { deep: true, immediate: true }
    )

    onMounted(() => {
        window.addEventListener('scroll', scrollHandler)
        // Call handleRouteChange when route changes
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', scrollHandler)
    })

    return { isElementVisible }
}
