<template>
    <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.32105 14.0016C3.17045 14.0055 2.06536 13.5372 1.24843 12.6994C0.419083 11.7884 -0.0290347 10.5758 0.00146043 9.32511C0.0319556 8.07442 0.5386 6.8867 1.41129 6.02006L5.49364 1.76548C6.38099 0.818403 7.59516 0.273467 8.87027 0.25C9.44304 0.253947 10.0094 0.375002 10.5367 0.606195C11.0641 0.837388 11.542 1.17415 11.9429 1.5971C12.7835 2.51344 13.2385 3.73704 13.2079 4.99964C13.1774 6.26224 12.6639 7.4608 11.78 8.33257L10.412 9.74702C10.3375 9.82974 10.2476 9.89609 10.1477 9.94211C10.0478 9.98812 9.93996 10.0129 9.83062 10.0149C9.72129 10.0169 9.61268 9.99606 9.51129 9.95372C9.40989 9.91137 9.31779 9.84834 9.24046 9.7684C9.16314 9.68845 9.10218 9.59321 9.06123 9.48838C9.02027 9.38354 9.00016 9.27125 9.00209 9.15821C9.00402 9.04516 9.02795 8.93367 9.07245 8.8304C9.11696 8.72712 9.18113 8.63417 9.26113 8.55709L10.5857 7.09774C11.1554 6.54495 11.491 5.78196 11.5194 4.97471C11.5479 4.16746 11.267 3.38124 10.7377 2.78703C10.1592 2.26537 9.40954 1.99221 8.64247 2.02351C7.87541 2.05482 7.14895 2.38822 6.61195 2.95542L2.56217 7.19877C2.00315 7.75024 1.67358 8.50494 1.64318 9.30319C1.61279 10.1014 1.88396 10.8806 2.39931 11.4758C2.68255 11.7652 3.02391 11.9866 3.39918 12.1244C3.77445 12.2621 4.17444 12.3128 4.57077 12.2728C4.67637 12.262 4.78299 12.2729 4.88449 12.3049C4.98598 12.337 5.08034 12.3895 5.16211 12.4594C5.24387 12.5293 5.31143 12.6153 5.36088 12.7124C5.41033 12.8094 5.44069 12.9157 5.45022 13.025C5.47145 13.2467 5.40681 13.4681 5.27045 13.6406C5.1341 13.8132 4.93716 13.9228 4.72277 13.9455L4.32105 14.0016Z"
            fill="white"
        />
        <path
            d="M10.1301 13.7194C9.55735 13.7155 8.99099 13.5944 8.46366 13.3632C7.93633 13.132 7.45843 12.7952 7.0575 12.3723C6.21685 11.456 5.76193 10.2324 5.79246 8.96976C5.82299 7.70717 6.33648 6.5086 7.22036 5.63683L8.58838 4.22238C8.66293 4.13966 8.75283 4.07331 8.85271 4.0273C8.9526 3.98128 9.06043 3.95654 9.16976 3.95454C9.2791 3.95255 9.38771 3.97334 9.4891 4.01569C9.59049 4.05803 9.6826 4.12106 9.75993 4.20101C9.83725 4.28096 9.89821 4.37619 9.93916 4.48102C9.98012 4.58586 10.0002 4.69815 9.9983 4.8112C9.99637 4.92424 9.97244 5.03573 9.92793 5.13901C9.88343 5.24228 9.81926 5.33523 9.73926 5.41231L8.41466 6.87167C7.84497 7.42445 7.50941 8.18744 7.48094 8.99469C7.45248 9.80194 7.73342 10.5882 8.26266 11.1824C8.84124 11.704 9.59085 11.9772 10.3579 11.9459C11.125 11.9146 11.8514 11.5812 12.3884 11.014L16.4816 6.77063C17.0346 6.2132 17.3562 5.45527 17.3785 4.65711C17.4008 3.85895 17.122 3.0831 16.6011 2.49361C16.3178 2.2042 15.9765 1.98277 15.6012 1.84502C15.2259 1.70728 14.826 1.6566 14.4296 1.69657C14.324 1.7074 14.2174 1.69648 14.1159 1.66446C14.0144 1.63244 13.92 1.57995 13.8383 1.51002C13.7565 1.44009 13.689 1.35411 13.6395 1.25703C13.5901 1.15996 13.5597 1.05372 13.5502 0.944446C13.5289 0.722715 13.5936 0.501322 13.7299 0.328779C13.8663 0.156236 14.0632 0.0466135 14.2776 0.0239307C14.9096 -0.0438044 15.5483 0.032676 16.1486 0.247977C16.749 0.463278 17.2963 0.812154 17.752 1.26999C18.5813 2.181 19.0294 3.3936 18.9989 4.64429C18.9684 5.89499 18.4618 7.0827 17.5891 7.94934L13.5067 12.2039C12.6194 13.151 11.4052 13.6959 10.1301 13.7194Z"
            fill="white"
        />
    </svg>
</template>
