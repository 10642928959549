<template>
    <div class="burger-icon" :class="{ active: active }">
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss"></style>
