<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M26.3758 17.8969C26.3758 12.9833 22.71 9 18.1879 9C13.6658 9 10 12.9833 10 17.8969C10 22.3375 12.9942 26.0182 16.9085 26.6857V20.4686H14.8296V17.8969H16.9085V15.9368C16.9085 13.707 18.131 12.4753 20.0012 12.4753C20.8968 12.4753 21.8341 12.6491 21.8341 12.6491V14.8386H20.8016C19.7845 14.8386 19.4673 15.5244 19.4673 16.2287V17.8969H21.7381L21.3751 20.4686H19.4673V26.6857C23.3816 26.0182 26.3758 22.3375 26.3758 17.8969Z"
            fill="#050F38"
        />
    </svg>

    <!-- <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1175_11851)">
      <path
        d="M13.2682 6.71723C13.2682 3.23609 10.3839 0.414062 6.82598 0.414062C3.26805 0.414062 0.383789 3.23609 0.383789 6.71723C0.383789 9.86328 2.73959 12.471 5.81938 12.9438V8.53924H4.18367V6.71723H5.81938V5.32857C5.81938 3.74883 6.78118 2.87624 8.2527 2.87624C8.95731 2.87624 9.69476 2.99935 9.69476 2.99935V4.55052H8.88244C8.0822 4.55052 7.83257 5.03643 7.83257 5.53539V6.71723H9.61927L9.33365 8.53924H7.83257V12.9438C10.9124 12.471 13.2682 9.86328 13.2682 6.71723Z"
        fill="#2A2A2A" />
    </g>
    <defs>
      <clipPath id="clip0_1175_11851">
        <rect
          width="12.8844"
          height="12.6063"
          fill="white"
          transform="translate(0.383789 0.414062)" />
      </clipPath>
    </defs>
  </svg> -->
</template>
