<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M23.6009 10H26.0544L20.6943 16.3538L27 25H22.062L18.1949 19.7562L13.7701 25H11.3152L17.0483 18.2038L11 10H16.0619L19.5574 14.7931L23.6009 10ZM22.7399 23.4769H24.0993L15.3239 11.4431H13.8651L22.7399 23.4769Z"
            fill="#050F38"
        />
    </svg>

    <!-- <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.52373 6.77566L15.4811 0H14.0699L8.89493 5.88201L4.7648 0H0L6.24693 8.89549L0 15.9999H1.4112L6.87253 9.78701L11.2352 15.9999H16M1.92053 1.04126H4.08853L14.0688 15.0098H11.9003"
            fill="black"
        />
    </svg> -->
    <!-- <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M15.1517 25.4579C21.3297 25.4579 24.7098 19.895 24.7098 15.0722C24.7098 14.9158 24.7066 14.756 24.7002 14.5996C25.3577 14.0829 25.9251 13.4429 26.3758 12.7097C25.7634 13.0057 25.1133 13.1991 24.4475 13.2831C25.1485 12.8266 25.6734 12.1093 25.9248 11.2643C25.2653 11.689 24.5441 11.9885 23.7921 12.1502C23.2854 11.5652 22.6155 11.1778 21.8859 11.048C21.1563 10.9182 20.4076 11.0532 19.7557 11.4321C19.1038 11.811 18.5849 12.4127 18.2792 13.1442C17.9736 13.8757 17.8982 14.6962 18.0648 15.4789C16.7294 15.406 15.4231 15.0291 14.2304 14.3725C13.0377 13.7159 11.9854 12.7943 11.1415 11.6674C10.7126 12.4709 10.5814 13.4217 10.7745 14.3266C10.9675 15.2314 11.4705 16.0224 12.181 16.5388C11.6476 16.5204 11.1258 16.3644 10.6589 16.0836V16.1287C10.6584 16.9719 10.9267 17.7893 11.4181 18.4419C11.9095 19.0944 12.5937 19.542 13.3545 19.7083C12.8603 19.8552 12.3417 19.8766 11.8388 19.7709C12.0534 20.496 12.4711 21.1303 13.0335 21.5851C13.5958 22.0399 14.2748 22.2926 14.9757 22.3079C13.7858 23.3235 12.3159 23.8744 10.8028 23.8718C10.5345 23.8714 10.2664 23.8535 10 23.8183C11.5372 24.8898 13.3253 25.459 15.1517 25.4579Z"
            fill="#050F38"
        />
    </svg> -->

    <!-- <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1175_11849)">
      <path
        d="M4.22517 11.8368C9.086 11.8368 11.7454 7.89558 11.7454 4.47881C11.7454 4.36802 11.7429 4.25476 11.7379 4.14396C12.2552 3.7779 12.7017 3.32449 13.0562 2.80503C12.5744 3.01476 12.0629 3.15174 11.5391 3.21128C12.0906 2.88781 12.5036 2.37965 12.7014 1.78101C12.1826 2.08187 11.6151 2.29411 11.0234 2.40862C10.6248 1.99416 10.0977 1.71973 9.52361 1.62778C8.94956 1.53582 8.36053 1.63145 7.8476 1.89989C7.33467 2.16832 6.9264 2.59461 6.68592 3.11284C6.44544 3.63107 6.38613 4.21238 6.51718 4.76689C5.46654 4.7153 4.43872 4.44827 3.50033 3.98309C2.56194 3.51791 1.73394 2.86498 1.07001 2.06662C0.73256 2.63586 0.629301 3.30946 0.781215 3.95052C0.933129 4.59157 1.32882 5.15198 1.88786 5.51785C1.46817 5.50481 1.05766 5.39425 0.69027 5.19531V5.22731C0.689894 5.82469 0.900968 6.40376 1.28761 6.86609C1.67426 7.32842 2.21261 7.64547 2.81116 7.76336C2.42238 7.86743 2.01433 7.8826 1.6186 7.80768C1.7875 8.32142 2.11611 8.77076 2.55858 9.09298C3.00105 9.4152 3.5353 9.59422 4.08676 9.60506C3.15054 10.3246 1.99403 10.7149 0.803511 10.713C0.592382 10.7127 0.38146 10.7001 0.171875 10.6751C1.38132 11.4343 2.78823 11.8375 4.22517 11.8368Z"
        fill="#2A2A2A" />
    </g>
    <defs>
      <clipPath id="clip0_1175_11849">
        <rect
          width="12.8844"
          height="12.6063"
          fill="white"
          transform="translate(0.171875 0.414062)" />
      </clipPath>
    </defs>
  </svg> -->
</template>
