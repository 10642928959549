<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M25.1636 10H11.209C10.5405 10 10 10.5412 10 11.2103V25.5801C10 26.2493 10.5405 26.7937 11.209 26.7937H25.1636C25.8321 26.7937 26.3758 26.2493 26.3758 25.5834V11.2103C26.3758 10.5412 25.8321 10 25.1636 10ZM14.8584 24.3108H12.4276V16.2944H14.8584V24.3108ZM13.643 15.2021C12.8626 15.2021 12.2325 14.556 12.2325 13.7589C12.2325 12.9619 12.8626 12.3157 13.643 12.3157C14.4202 12.3157 15.0503 12.9619 15.0503 13.7589C15.0503 14.5527 14.4202 15.2021 13.643 15.2021ZM23.9546 24.3108H21.527V20.4141C21.527 19.4858 21.511 18.2886 20.2637 18.2886C19.0003 18.2886 18.8084 19.3022 18.8084 20.3485V24.3108H16.384V16.2944H18.7124V17.3899H18.7444C19.0675 16.7601 19.8607 16.0943 21.0409 16.0943C23.5004 16.0943 23.9546 17.754 23.9546 19.9122V24.3108Z"
            fill="#050F38"
        />
    </svg>

    <!-- <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1175_11841)">
      <path
        d="M12.6777 0.414062H1.6983C1.17236 0.414062 0.74707 0.820322 0.74707 1.32261V12.1094C0.74707 12.6117 1.17236 13.0204 1.6983 13.0204H12.6777C13.2036 13.0204 13.6314 12.6117 13.6314 12.1119V1.32261C13.6314 0.820322 13.2036 0.414062 12.6777 0.414062ZM4.5696 11.1565H2.65708V5.13898H4.5696V11.1565ZM3.61334 4.31907C2.99932 4.31907 2.50357 3.83403 2.50357 3.23572C2.50357 2.63741 2.99932 2.15236 3.61334 2.15236C4.22484 2.15236 4.72059 2.63741 4.72059 3.23572C4.72059 3.83156 4.22484 4.31907 3.61334 4.31907ZM11.7265 11.1565H9.81646V8.23147C9.81646 7.53468 9.80388 6.63598 8.82245 6.63598C7.82844 6.63598 7.67745 7.3968 7.67745 8.18223V11.1565H5.76996V5.13898H7.60196V5.96135H7.62712C7.88129 5.48861 8.50538 4.98879 9.43396 4.98879C11.3691 4.98879 11.7265 6.23465 11.7265 7.85476V11.1565Z"
        fill="#2A2A2A" />
    </g>
    <defs>
      <clipPath id="clip0_1175_11841">
        <rect
          width="12.8844"
          height="12.6063"
          fill="white"
          transform="translate(0.74707 0.414062)" />
      </clipPath>
    </defs>
  </svg> -->
</template>
