<script lang="ts" setup>
import { useHeaderMainStore } from '~/store'

const store = useHeaderMainStore()
const isOpenedSubMenu = ref(false)

function onClickItem(slug: string) {
    // if (isOpenedSubMenu.value) {
    //     document.querySelector('body').style.overflow = 'initial'
    // } else {
    //     document.querySelector('body').style.overflow = 'hidden'
    // }

    if (slug === 'what-we-do') {
        isOpenedSubMenu.value = !isOpenedSubMenu.value
    }
    if (!isOpenedSubMenu.value) {
        showSubSubMenu.value = false
        selectedDepartmentType.value = ''
    }
}

const showSubSubMenu = ref(false)
const selectedDepartmentType = ref('')
const selectSubMenu = (name: string) => {
    showSubSubMenu.value = true
    selectedDepartmentType.value = name
}

const backToMain = () => {
    isOpenedSubMenu.value = false
    showSubSubMenu.value = false
    selectedDepartmentType.value = ''
}
const backToSubMenu = () => {
    showSubSubMenu.value = false
    selectedDepartmentType.value = ''
}
</script>

<template>
    <!-- Burger -->
    <div class="burger">
        <div class="burger-container">
            <div class="left-block">
                <!-- burger-logo -->
                <div class="burger-logo">
                    <img
                        src="~/assets/icons/gratawhite.png"
                        width="86"
                        height="85"
                    />
                </div>
                <!-- burger-menu -->
                <div class="burger-menu">
                    <!-- Класс "is-hidden" скрывает основной меню шапки -->
                    <div
                        class="burger-menu__main"
                        :class="{ 'is-hidden': isOpenedSubMenu }"
                    >
                        <!-- burger-links -->
                        <div class="burger-links">
                            <div
                                v-for="item in store.menuList"
                                :key="item?.slug"
                                class="burger-links__item"
                            >
                                <a
                                    v-if="item.slug === 'what-we-do'"
                                    class="burger-links__link burger-links__link--list has-sub-menu"
                                    @click="onClickItem(item?.slug)"
                                    >{{
                                        item.translations[0]?.name
                                    }}</a
                                >

                                <AppNuxtLinkLocale
                                    v-else-if="
                                        item.slug !== 'association'
                                    "
                                    :to="{
                                        path:
                                            item?.slug === 'regions'
                                                ? `/${store?.selectedRegion.slug}/regions`
                                                : `/${item.slug}`
                                    }"
                                    class="burger-links__link"
                                >
                                    {{ item.translations[0]?.name }}
                                </AppNuxtLinkLocale>
                                <template
                                    v-if="item.slug == 'association'"
                                >
                                    <a
                                        href="https://gi.gratanet.com"
                                        class="burger-links__link"
                                        >{{
                                            item.translations[0]?.name
                                        }}</a
                                    >
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="burger-buttons">
                        <AppNuxtLinkLocale
                            to="/contacts"
                            tag="button"
                            name="contact"
                            class="burger-button btn-contact"
                        >
                            {{ $t('burger.buttonContactLabel') }}
                        </AppNuxtLinkLocale>
                    </div>
                    <!-- burger-submenu -->
                    <!-- Класс "is-show" показывает внутреннее меню -->
                    <div
                        class="burger-submenu"
                        :class="{ 'is-show': isOpenedSubMenu }"
                    >
                        <div
                            class="burger-submenu__title"
                            style="cursor: pointer"
                            @click="backToMain"
                        >
                            {{ $t('buttonBackToMenu') }}
                        </div>
                        <a
                            class="burger-submenu__link burger-links__link--list"
                            :class="{
                                'is-active-sub-menu-item':
                                    selectedDepartmentType ===
                                    'industries'
                            }"
                            @click="selectSubMenu('industries')"
                            >{{ $t('industrySectors') }}
                        </a>

                        <a
                            class="burger-submenu__link burger-links__link--list"
                            :class="{
                                'is-active-sub-menu-item':
                                    selectedDepartmentType ===
                                    'specializations'
                            }"
                            @click="selectSubMenu('specializations')"
                            >{{ $t('practiceAreas') }}
                        </a>
                        <AppNuxtLinkLocale
                            :to="{ path: '/insights' }"
                            class="burger-submenu__link"
                            >{{ $t('insights') }}
                        </AppNuxtLinkLocale>

                        <AppNuxtLinkLocale
                            :to="{ path: '/career' }"
                            class="burger-submenu__link"
                            >{{ $t('career.career') }}
                        </AppNuxtLinkLocale>
                    </div>
                    <!-- burger-buttons -->
                </div>

                <img
                    src="~/assets/icons/globuswhite.png"
                    class="burger-globus"
                />

                <!-- Social links -->
                <div class="burger-socials">
                    <a
                        href="https://t.me/gratanet"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <telegram-icon></telegram-icon>
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCPxiS1gz0G6yjNzNLE9xz2A"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <youtube-icon></youtube-icon>
                    </a>
                    <a
                        href="https://www.facebook.com/gratanet/"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <facebook-icon></facebook-icon>
                    </a>
                    <a
                        href="https://twitter.com/gratanet"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <twitter-icon></twitter-icon>
                    </a>
                    <a
                        href="https://www.instagram.com/grata_international/"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <instagram-icon></instagram-icon>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/grata"
                        target="_blank"
                        class="burger-socials__item"
                    >
                        <linkedin-icon></linkedin-icon>
                    </a>
                </div>
            </div>
            <div v-show="showSubSubMenu" class="sub-sub-menu">
                <a
                    class="back-to-sub-menu burger-submenu__title"
                    @click="backToSubMenu"
                    >{{
                        selectedDepartmentType === 'specializations'
                            ? ' Practice Areas'
                            : 'Industry Sectors'
                    }}</a
                >
                <div class="sub-sub-menu__items">
                    <div
                        v-for="department in store[
                            selectedDepartmentType
                        ]"
                        :key="department.id"
                        class="sub-sub-menu__item"
                    >
                        <AppNuxtLinkLocale
                            :to="{
                                path: `/${selectedDepartmentType}/${department.slug}`
                            }"
                        >
                            {{ department.name }}
                        </AppNuxtLinkLocale>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.sub-sub-menu {
    background-color: #3b82f6;
    height: 100%;
    flex-grow: 1;

    padding-right: 144px;
    padding-left: 102px;
    padding-top: 75px;

    @include sm-block {
        padding-left: 8px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #000;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #3f3f3f;
    }

    &__items {
        height: 600px;
        width: 100%;
        // max-width: 620px;
        overflow-y: auto;

        @include sm-block {
            padding-left: 16px;
        }

        a {
            color: #fff;
            font-weight: 400;
            font-size: 20px;

            &:hover {
                color: #050f38;
            }
        }
    }

    &__item {
        margin-bottom: 40px;
    }

    .back-to-sub-menu {
        display: none;
        text-transform: capitalize;
        padding-left: 20px;
        left: 0 !important;
        color: #fff;
    }

    @media screen and (max-width: 640px) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        .back-to-sub-menu {
            display: block;
        }
    }
}

.left-block {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    //margin-left: 99px;
    padding-top: 86px;
    padding-bottom: 65px;
    padding-right: 0;
    justify-content: space-between;
    min-width: 610px;
    @include sm-block {
        margin-left: 0;
        min-width: 320px;
    }
}

.is-active-sub-menu-item {
    background-color: #3b82f6;

    &:hover {
        color: #fff;
    }
}
</style>
