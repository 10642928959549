<script lang="ts" setup>
import { ref, computed } from 'vue'
const route = useRoute()
const runtimeConfig = useRuntimeConfig()
const fullSourceTypeURL = computed(
    () => `${runtimeConfig.public.baseSiteURL}${route.fullPath}`
)
const shareHover = ref(false)
const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}
const copyActive = ref(false)
function copyLink() {
    navigator.clipboard.writeText(fullSourceTypeURL.value)
    copyActive.value = true
    setTimeout(() => {
        copyActive.value = false
    }, 2000)
}
</script>
<template>
    <div class="share-wrapper">
        <button
            class="btn-scroll-up"
            name="share to social"
            @click.stop="scrollToTop"
        >
            <div
                class="share__copied"
                :class="
                    copyActive
                        ? 'share__copied-active'
                        : 'share__copied-hidden'
                "
                @click.stop="copyActive = false"
            >
                <span>Link copied</span> ×
            </div>
            <svg
                width="14"
                height="55"
                viewBox="0 0 14 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14 6.97258C14.0005 7.0743 13.9805 7.17509 13.9413 7.26904C13.9021 7.36299 13.8443 7.4482 13.7715 7.51968C13.6255 7.66466 13.4275 7.74609 13.2211 7.74609C13.0147 7.74609 12.8168 7.66466 12.6707 7.51968L6.99028 1.87323L1.30982 7.51968C1.16218 7.65643 0.966894 7.73088 0.765117 7.72734C0.56334 7.7238 0.370822 7.64255 0.228122 7.50071C0.0854217 7.35886 0.00368146 7.1675 0.000121343 6.96693C-0.00343878 6.76636 0.0714591 6.57225 0.209036 6.42549L6.43989 0.231936C6.58593 0.0869546 6.78388 0.00552082 6.99028 0.00552082C7.19668 0.00552082 7.39464 0.0869546 7.54067 0.231936L13.7715 6.42549C13.8443 6.49696 13.9021 6.58218 13.9413 6.67612C13.9805 6.77007 14.0005 6.87086 14 6.97258Z"
                    fill="#3B82F6"
                />
                <path
                    d="M8 3.61582L8 52.39C8 53.083 7.89464 53.7476 7.70711 54.2376C7.51957 54.7276 7.26522 55.0039 7 55.0039C6.73478 55.0039 6.48043 54.7276 6.29289 54.2376C6.10536 53.7476 6 53.083 6 52.39L6 3.61582C6 2.92283 6.10536 2.25823 6.29289 1.76822C6.48043 1.2782 6.73478 1.00291 7 1.00291C7.26522 1.00291 7.51957 1.2782 7.70711 1.76822C7.89464 2.25823 8 2.92283 8 3.61582Z"
                    fill="#3B82F6"
                />
            </svg>
            <div class="btn-text-wrapper">
                <span class="btn-scroll-up__text">{{
                    $t('totop')
                }}</span>
            </div>
        </button>

        <div
            class="share"
            :class="{ 'share-active': shareHover }"
            @mouseleave="shareHover = false"
        >
            <div
                class="share__main"
                style="cursor: pointer"
                @click.stop="shareHover = !shareHover"
                @mouseenter="shareHover = true"
            >
                <a
                    @click.prevent="copyLink"
                    class="share__link share-1"
                >
                    <CopyIcon
                /></a>
                <a
                    :href="`mailto:?body=${fullSourceTypeURL}`"
                    target="_blank"
                    class="share__link share-2"
                >
                    <EnvelopeIcon
                /></a>
                <a
                    :href="`https://twitter.com/intent/tweet?url=${fullSourceTypeURL}`"
                    target="_blank"
                    class="share__link share-3"
                >
                    <TwitterIcon
                /></a>
                <a
                    :href="`https://www.facebook.com/sharer.php?u=${fullSourceTypeURL}`"
                    target="_blank"
                    class="share__link share-4"
                >
                    <FacebookIcon
                /></a>
                <a
                    :href="`https://www.linkedin.com/sharing/share-offsite/?url=${fullSourceTypeURL}`"
                    target="_blank"
                    class="share__link share-5"
                >
                    <LinkedinIcon
                /></a>
                <a
                    :href="`https://t.me/share/url?url=${fullSourceTypeURL}`"
                    target="_blank"
                    class="share__link share-6"
                >
                    <TelegramIcon
                /></a>

                <ShareAlt />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.btn-scroll-up {
    position: fixed;
    bottom: 100px;
    z-index: 1001;
    right: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    svg {
        margin-bottom: 4px;
        display: block;
    }
    &__text {
        display: inline-block;
        transform-origin: top left;
        margin-top: 4px;
        color: #888888;
        font-weight: 500;
        font-size: 1rem;
        white-space: nowrap;
        // transform: translateY(100%);
        writing-mode: vertical-rl;
    }
    @include sm-block {
        right: 16px;
        bottom: 40px;
    }
}

.btn-text-wrapper {
    position: relative;
    transform-origin: top left;
}

.share {
    position: fixed;
    z-index: 1001;
    right: 100px;
    bottom: 100px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    &__copied {
        background: #fff;
        box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
        padding: 6px 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        span {
            color: #077505;
            margin-right: 5px;
            font-size: 14px;
        }
        position: absolute;
        z-index: 1011;
        white-space: nowrap;
        bottom: 120%;
        right: -50px;
        // display: none;
        &-active {
            visibility: visible;
            opacity: 1;
        }
        &-hidden {
            visibility: hidden;
            opacity: 0;
            transition:
                visibility 0s 2s,
                opacity 0.3s linear;
        }
    }

    &__link {
        position: absolute;
        background: #3b82f6;
        // display: none;
        z-index: 1018;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        visibility: hidden;
        color: #fff;
        will-change: animation;
        left: 50%;
        right: unset;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            circle {
                display: none;
            }
            path {
                fill: #fff;
            }
        }
    }
    &__1 {
        display: none;
    }
    &__main {
        background: #3b82f6;
        border-radius: 100%;
        // padding: 35px;
        width: 70px;
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}
.share-active {
    height: 400px;
    .share-1 {
        will-change: animation;
        animation: 0.5s up forwards;
        display: flex;
        @keyframes up {
            0% {
                visibility: visible;
                bottom: 0;
            }

            78% {
                bottom: 130%;
            }
            100% {
                visibility: visible;
                bottom: 120%;
            }
        }
    }
    .share-2 {
        animation: 0.5s up1 forwards;
        animation-delay: 0.15s;
        @keyframes up1 {
            0% {
                visibility: visible;
                bottom: 120%;
            }

            78% {
                bottom: 190%;
            }
            100% {
                visibility: visible;
                bottom: 180%;
            }
        }
    }
    .share-3 {
        animation: 0.5s up2 forwards;
        animation-delay: 0.3s;
        @keyframes up2 {
            0% {
                bottom: 180%;
                visibility: visible;
            }

            78% {
                bottom: 250%;
            }
            100% {
                bottom: 240%;
                visibility: visible;
            }
        }
    }
    .share-4 {
        animation: 0.5s up3 forwards;
        animation-delay: 0.45s;
        @keyframes up3 {
            0% {
                visibility: visible;
                bottom: 240%;
            }

            78% {
                bottom: 310%;
            }
            100% {
                bottom: 300%;
                visibility: visible;
            }
        }
    }
    .share-5 {
        animation: 0.5s up4 forwards;
        animation-delay: 0.6s;
        @keyframes up4 {
            0% {
                visibility: visible;
                bottom: 300%;
            }

            78% {
                bottom: 370%;
            }
            100% {
                bottom: 360%;
                visibility: visible;
            }
        }
    }
    .share-6 {
        animation: 0.5s up5 forwards;
        animation-delay: 0.75s;
        @keyframes up5 {
            0% {
                visibility: visible;
                bottom: 360%;
            }

            78% {
                bottom: 430%;
            }
            100% {
                bottom: 420%;
                visibility: visible;
            }
        }
    }
}
// @media screen and (max-width: 590px)
@include sm-block() {
    .share {
        right: unset;
        right: 40px;
        bottom: 45px;
        &__main {
            width: 41px;
            height: 41px;
            svg {
                width: 24px;
                height: 24px;
            }
        }

        &__link {
            width: 24px;
            height: 24px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
