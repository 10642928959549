<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="18"
            cy="18"
            r="18"
            fill="white"
            fill-opacity="0.5"
        />
        <path
            d="M23.2922 11H12.7076H12.7078C11.9895 11 11.3008 11.3347 10.7931 11.9308C10.2852 12.5267 10 13.3349 10 14.1779V20.8223V20.8221C10 21.6651 10.2852 22.4733 10.7931 23.0692C11.3008 23.6653 11.9895 24 12.7078 24H23.2924H23.2922C24.0105 24 24.6992 23.6653 25.2069 23.0692C25.7148 22.4733 26 21.6651 26 20.8221V14.1777V14.1779C26 13.3349 25.7148 12.5267 25.2069 11.9308C24.6992 11.3347 24.0105 11 23.2922 11ZM21.1336 17.6269L16.3336 20.5158C16.2955 20.5388 16.25 20.5372 16.2134 20.5112C16.1766 20.4854 16.1542 20.4388 16.1538 20.3886V14.6109C16.1542 14.5607 16.1766 14.5141 16.2134 14.4883C16.25 14.4623 16.2955 14.4607 16.3336 14.4837L21.1336 17.3726C21.1746 17.3974 21.2005 17.447 21.2005 17.5012C21.2005 17.5554 21.1746 17.6049 21.1336 17.6297V17.6269Z"
            fill="#050F38"
        />
    </svg>

    <!-- <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4278 0.414062H3.49489H3.49509C2.68535 0.414062 1.90892 0.738641 1.33651 1.31667C0.763897 1.89451 0.442383 2.6783 0.442383 3.49571V9.93895V9.93876C0.442383 10.7562 0.763912 11.54 1.33651 12.1178C1.90892 12.6958 2.68535 13.0204 3.49509 13.0204H15.428H15.4278C16.2375 13.0204 17.014 12.6958 17.5864 12.1178C18.159 11.54 18.4805 10.7562 18.4805 9.93876V3.49552V3.49571C18.4805 2.6783 18.159 1.89451 17.5864 1.31667C17.014 0.738626 16.2375 0.414062 15.4278 0.414062ZM12.9942 6.84029L7.58276 9.6417C7.53978 9.66398 7.48849 9.66242 7.44725 9.63721C7.40583 9.6122 7.38047 9.56706 7.38008 9.5184V3.91558C7.38047 3.86692 7.40583 3.82178 7.44725 3.79677C7.48848 3.77156 7.53978 3.77 7.58276 3.79228L12.9942 6.59369C13.0405 6.61772 13.0697 6.66579 13.0697 6.71836C13.0697 6.77093 13.0405 6.81899 12.9942 6.84303V6.84029Z"
      fill="#2A2A2A" />
  </svg> -->
</template>
