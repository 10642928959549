import { useI18n } from 'vue-i18n'

export default function useHomePageDetection() {
    const i18n = useI18n()
    const route = useRoute()
    const currentPath = route.path
    const homePageLanguagePrefixes = computed<string[]>(
        () => i18n.availableLocales
    )

    const isHomePage = computed(() => {
        // Check if the current route is the root path or a language prefix without additional segments
        return (
            currentPath === '/' ||
            homePageLanguagePrefixes.value.includes(
                currentPath.substring(1)
            )
        )
    })

    return { isHomePage }
}
