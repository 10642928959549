import { useHeaderMainStore } from '~/store'

export function useGetDepartments() {
    const store = useHeaderMainStore()

    function getDepartments(countryId: number) {
        store.$patch({
            industries: [],
            specializations: []
        })

        return useHttpClient('/departmentContents', {
            query: {
                countryId,
                size: 1000,
                projection: 'simple'
            }
        })
            .then(response => {
                const data =
                    response.data?.value?._embedded
                        ?.departmentContents
                store.$patch({
                    industries: data?.filter(
                        d => d.departmentTypeCode === '7'
                    ),
                    specializations: data?.filter(
                        d =>
                            d.departmentTypeCode === '9' ||
                            d.departmentTypeCode === '10'
                    )
                })
            })
            .catch(e => {
                console.error(
                    'DEPARTMENTS RESPONSE PATCH TO STORE ',
                    e
                )
            })
    }

    return { getDepartments }
}
