<script lang="ts" setup>
import { ref } from 'vue'
// import AnimatedLoading from '~/components/AnimatedLoading.vue'

const email = ref('')
const isLoading = ref(false)

async function sendData() {
    isLoading.value = true
    const { status } = await useHttpClient('/subscribeByEmail', {
        method: 'post',
        body: { email }
    })

    if (status === 'success') {
        isLoading.value = false
        return
    }
    isLoading.value = false
}
</script>

<template>
    <a
        class="footer-search"
        style="cursor: pointer"
        href="
            https://forms.sendpulse.com/222211227d/
        "
    >
        <div class="footer-search__input">
            <button
                name="send-email"
                style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    color: #fff;
                    gap: 4px;
                "
                @click="sendData"
            >
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_4594_7840)">
                        <path
                            d="M11.5267 8.74819C11.4684 8.74819 10.1588 8.5148 10.1588 4.69633C10.1588 1.9994 8.58995 0.320312 5.99676 0.320312C3.40357 0.320312 1.83468 1.9994 1.83468 4.69633C1.83468 8.58611 0.479741 8.74819 0.486224 8.74819C0.357269 8.74819 0.233596 8.79942 0.142412 8.8906C0.0512269 8.98178 0 9.10546 0 9.23441C0 9.36337 0.0512269 9.48704 0.142412 9.57823C0.233596 9.66941 0.357269 9.72064 0.486224 9.72064H3.6175C3.72573 10.2721 4.02219 10.7689 4.45618 11.126C4.89017 11.4831 5.43474 11.6784 5.99676 11.6784C6.55878 11.6784 7.10335 11.4831 7.53734 11.126C7.97133 10.7689 8.26779 10.2721 8.37601 9.72064H11.5138C11.6427 9.72064 11.7664 9.66941 11.8576 9.57823C11.9488 9.48704 12 9.36337 12 9.23441C12 9.10546 11.9488 8.98178 11.8576 8.8906C11.7664 8.79942 11.6427 8.74819 11.5138 8.74819H11.5267ZM5.99676 10.6931C5.69614 10.6925 5.40311 10.5987 5.15809 10.4245C4.91308 10.2503 4.72816 10.0044 4.62885 9.72064H7.36467C7.26536 10.0044 7.08044 10.2503 6.83542 10.4245C6.59041 10.5987 6.29738 10.6925 5.99676 10.6931ZM1.95138 8.74819C2.41815 8.04154 2.80713 6.80329 2.80713 4.69633C2.80713 2.58936 3.97407 1.29276 5.99676 1.29276C8.01945 1.29276 9.18639 2.53101 9.18639 4.69633C9.18639 6.86164 9.57536 8.04154 10.0421 8.74819H1.95138Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_4594_7840">
                            <rect
                                width="12"
                                height="12"
                                fill="white"
                            />
                        </clipPath>
                    </defs>
                </svg>

                <span>{{ $t('formSubscribeByEmail.title') }}</span>
                <span></span>
            </button>
        </div>
    </a>
</template>

<style lang="scss" scoped></style>
