<script lang="ts" setup>
import HeaderBurgerMenuModal from '~/components/Header/HeaderBurgerMenuModal.vue'
import HeaderSearchCloseButton from '~/components/Header/HeaderSearchCloseButton.vue'
import { useHeaderMainStore } from '~/store'
import { useBackNavigation } from '~/composables/useBackNavigation'

const searchActive = ref(false)
const burgerActive = ref(false)
const openModal = ref(false)
const headerHeight = ref(0)
const store = useHeaderMainStore()
const { getDepartments } = useGetDepartments()
const route = useRoute()

const getHeaderData = async (slug?: string) => {
    await store
        .getHeaderData(
            true,
            slug || (route.query.region as string) || 'global'
        )
        .then(() => {
            getDepartments(store.selectedRegion?.id || -1)
        })
}

await getHeaderData()

watch(
    () => route,
    async newRoute => {
        console.log('new route back', newRoute.query)

        await getHeaderData(newRoute.query.region as string)
    },
    { deep: true, immediate: true }
)

onMounted(() => {
    const headerEl = document?.querySelector('.header') as HTMLElement
    headerHeight.value = headerEl?.offsetHeight || 0

    useBackNavigation(async () => {
        const queryParams = new URLSearchParams(
            window?.location?.search
        )
        const region = queryParams?.get('region')
        await getHeaderData(region as string)
    })
})

useBackNavigation(async () => {
    const queryParams = new URLSearchParams(window?.location?.search)
    const region = queryParams?.get('region')
    await getHeaderData(region as string)
})
const closeModals = () => {
    openModal.value = false
    burgerActive.value = false
}
watch(
    () => route.path,
    newVal => {
        if (newVal !== '/insights') {
            searchActive.value = false
        }
        burgerActive.value = false
    },
    { deep: true }
)
const i18n = useI18n()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateKey = computed(() =>
    route.query.region
        ? route.query.region + '-' + i18n.locale.value
        : `global-${i18n.locale.value}`
)
const bodyEl = process.client ? document?.querySelector('body') : null
watch(
    () => burgerActive,
    newVal => {
        if (newVal.value && bodyEl) {
            bodyEl.style.overflow = 'hidden'
        } else if (bodyEl) {
            bodyEl.style.overflow = 'initial'
        }
    },
    { deep: true }
)

// watch(
//     () => route,
//     () => {
//         if (route.hash) {
//             store.isHeaderFixed = true
//         }
//     },
//     { deep: true, immediate: true }
// ) --- вызывало 2557
</script>

<template>
    <header id="header" class="header-wrapper">
        <div ref="header" class="header">
            <div class="container header-container">
                <!-- Header logo -->
                <AppNuxtLinkLocale
                    :to="{ path: '/', query: {} }"
                    class="header__logo"
                >
                    <div class="header-logo">
                        <HeaderLogo
                            :is-fixed="store?.isHeaderFixed"
                        />
                    </div>
                    <div class="header-logo-mobile">
                        <HeaderLogoMobile
                            :is-fixed="store?.isHeaderFixed"
                        />
                    </div>
                </AppNuxtLinkLocale>

                <!-- Header links -->
                <HeaderMenu
                    v-if="!searchActive"
                    :header-height="headerHeight"
                />

                <!-- Header search -->
                <HeaderSearch v-else />
                <!-- Header buttons -->
                <div
                    v-show="!searchActive"
                    class="header__buttons header-buttons"
                >
                    <!-- header-buttons__search -->
                    <button
                        name="search"
                        class="header-buttons__search"
                        @click="searchActive = !searchActive"
                    >
                        <svg
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.75 4.6875C8.74492 4.6875 4.6875 8.74492 4.6875 13.75C4.6875 18.7551 8.74492 22.8125 13.75 22.8125C16.2008 22.8125 18.4244 21.8396 20.0557 20.259C20.0837 20.2208 20.115 20.1842 20.1496 20.1496C20.1842 20.115 20.2208 20.0837 20.259 20.0557C21.8396 18.4244 22.8125 16.2008 22.8125 13.75C22.8125 8.74492 18.7551 4.6875 13.75 4.6875ZM22.1186 20.7928C23.7216 18.89 24.6875 16.4328 24.6875 13.75C24.6875 7.70938 19.7906 2.8125 13.75 2.8125C7.70938 2.8125 2.8125 7.70938 2.8125 13.75C2.8125 19.7906 7.70938 24.6875 13.75 24.6875C16.4328 24.6875 18.89 23.7216 20.7928 22.1186L25.5871 26.9129C25.9532 27.2791 26.5468 27.2791 26.9129 26.9129C27.2791 26.5468 27.2791 25.9532 26.9129 25.5871L22.1186 20.7928Z"
                                fill="#2F363D"
                            />
                        </svg>
                    </button>

                    <LanguageSwitcher />

                    <!-- header-buttons__burger -->
                    <div
                        class="header-buttons__burger"
                        @click="burgerActive = !burgerActive"
                    >
                        <BurgerButton :active="burgerActive" />
                    </div>
                </div>

                <!-- Search active block -->
                <HeaderSearchCloseButton
                    v-show="searchActive"
                    @close="searchActive = !searchActive"
                />
            </div>

            <HeaderBurgerMenuModal v-if="burgerActive" />
        </div>

        <!-- Popup menu (@mouseleave="hover = ''") -->

        <div
            v-if="openModal || burgerActive"
            class="overlay"
            @click="closeModals"
        ></div>
    </header>
</template>
