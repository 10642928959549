<script lang="ts" setup></script>

<template>
    <div @click="$emit('close')">
        <span class="header__search-bigcancel"
            ><svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.7997 12.5212L24.4773 2.84354C24.6387 2.69321 24.7681 2.51191 24.8578 2.31048C24.9476 2.10905 24.9959 1.8916 24.9997 1.67111C25.0036 1.45062 24.9631 1.2316 24.8805 1.02713C24.7979 0.822651 24.675 0.636906 24.519 0.480972C24.3631 0.325037 24.1773 0.202107 23.9729 0.119517C23.7684 0.0369258 23.5494 -0.00363474 23.3289 0.00025556C23.1084 0.00414586 22.891 0.0524072 22.6895 0.14216C22.4881 0.231912 22.3068 0.361318 22.1565 0.522656L12.4788 10.2003L2.80114 0.522656C2.48984 0.232589 2.07811 0.0746734 1.65269 0.0821795C1.22726 0.0896857 0.821356 0.262027 0.520488 0.562895C0.21962 0.863764 0.0472779 1.26967 0.0397718 1.69509C0.0322656 2.12052 0.190181 2.53225 0.480248 2.84354L10.1579 12.5212L0.480248 22.1989C0.17273 22.5068 0 22.9241 0 23.3593C0 23.7945 0.17273 24.2119 0.480248 24.5198C0.78815 24.8273 1.20552 25 1.64069 25C2.07586 25 2.49323 24.8273 2.80114 24.5198L12.4788 14.8421L22.1565 24.5198C22.4644 24.8273 22.8817 25 23.3169 25C23.7521 25 24.1694 24.8273 24.4773 24.5198C24.7849 24.2119 24.9576 23.7945 24.9576 23.3593C24.9576 22.9241 24.7849 22.5068 24.4773 22.1989L14.7997 12.5212Z"
                    fill="#2A2A2A"
                /></svg
        ></span>
    </div>
</template>

<style lang="scss" scoped></style>
