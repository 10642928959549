<!-- eslint-disable @typescript-eslint/no-this-alias -->
<script setup>
import WidgetShare from '~/components/WidgetShare.vue'
import useElementScrollVisibility from '~/composables/useElementScrollVisibility'
import { useHeaderMainStore } from '~/store'

const store = useHeaderMainStore()

const head = useLocaleHead({
    addDirAttribute: true, // Adds dir
    addSeoAttributes: true // Adds lang
})
const htmlAttrs = computed(() => head.value.htmlAttrs)
const { locale } = useI18n()

// const route = useRoute()
const { isHomePage } = useHomePageDetection()
const { isElementVisible } = useElementScrollVisibility(
    85,
    'sliderHomeIntroId'
)
const { isElementVisible: isBreadcrumVisible } =
    useElementScrollVisibility(85, 'breadcrumbs')
const { isElementVisible: isPageIntroVisible } =
    useElementScrollVisibility(85, 'pageIntro')
const isWidgetVisible = computed(
    () =>
        (isElementVisible.value && isHomePage.value) ||
        isBreadcrumVisible.value ||
        isPageIntroVisible.value
)

// function capitalizeFirstLetter(string) {
//     return string?.charAt(0)?.toUpperCase() + string?.slice(1)
// }

let timeoutId

function debounce(func, delay) {
    return function () {
        const context = this
        const args = arguments
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => func.apply(context, args), delay)
    }
}

const scrollHandler = debounce(function () {
    if (window.scrollY > 70) {
        store.$patch({ isHeaderFixed: true })
    } else {
        store.$patch({ isHeaderFixed: false })
    }
}, 0)

onMounted(() => {
    window.addEventListener('scroll', scrollHandler)
})

onUnmounted(() => {
    window.removeEventListener('scroll', scrollHandler)
})
</script>

<template>
    <Html :lang="locale" :dir="htmlAttrs?.dir" :class="{}">
        <Body
            :class="{
                reverse: locale === 'ar',
                'is-header-fixed': store?.isHeaderFixed
            }"
        >
            <NuxtLoadingIndicator />
            <HeaderMain />
            <slot />
            <FooterMain />
            <!-- Cookies -->
            <div class="cookies"></div>
            <WidgetShare v-if="isWidgetVisible" />
        </Body>
    </Html>
</template>
